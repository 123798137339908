import React, { useState } from "react"
import "../assets/styles/index.scss"
import Head from "../components/global/head"
import ModalVideo from "react-modal-video"

import banner2x from "../assets/images/8bar/banner2@2x.jpg"
import block1 from "../assets/images/8bar/block1.jpg"
import block2 from "../assets/images/8bar/block2.jpg"
import block3 from "../assets/images/8bar/block3.jpg"
import block4 from "../assets/images/8bar/block4.jpg"

const EightBar = () => {
  const [showFullStudy, setShowFullStudy] = useState(false)
  const [showVideo, setShowVideo] = useState(false)

  const handleOpenVideo = (e) => {
    e.preventDefault()
    setShowVideo(true)
  }
  const handleCloseVideo = () => {
    setShowVideo(false)
  }
  return (
    <>
      <Head
        description="Hardhat is an independent creative agency helping brands capitalise on the why, when, where, what and how of human behaviour."
        title="Case Study - 8 Bar"
        url="https://hardhat.com.au/8bar"
        image="https://hardhat.com.au/images/og.jpg"
        bodyClassName="old-case-studies"
      />
      <div>
        <div className="container case-study dark-header">
          <section className="hhd-head-banner js-banner-intro-animation">
            <h1>8 BAR - BREAK IT TO YOUR BARISTA</h1>
            <div className="divider black center" />
          </section>
          <section className="hhd-head-banner no-padding js-banner-intro-animation">
            <div
              className="banner-image"
              style={{
                backgroundImage: `url(${banner2x})`,
              }}
            >
              8 BAR - BREAK IT TO YOUR BARISTA
            </div>
          </section>
          <section className="hhd-case-intro js-fade-in-animation">
            <div className="hhd-case-intro-text">
              <p>
                An integrated, digitally led campaign to launch the brand
                nationally and position 8 Bar as the only choice for real coffee
                lovers.
              </p>
              <hr />
            </div>
            <div className="hhd-case-intro-more">
              <div
                className={`hhd-case-intro-more__full ${
                  showFullStudy ? "show" : "hide"
                }`}
              >
                <br />
                <br />
                <h4>THE CHALLENGE</h4>
                <p>
                  In 2016, Devondale Murray Goulburn launched 8 Bar Iced Coffee,
                  a young, upstart ready-to-drink iced coffee brand. Over 40
                  years of competition in the iced coffee market had resulted in
                  a stale, undifferentiated category, with incumbent brands
                  battling fiercely for the ‘bloke brick’ consumer - Australians
                  looking for a sugary coffee energy hit in a big bottle or
                  carton.
                </p>
                <p>
                  8 Bar Iced Coffee brought a breath of fresh air to the
                  category by appealing to a whole new audience of coffee
                  lovers. Carefully developed to ensure an authentic,
                  cafe-quality flavour profile, 8 Bar is made with real espresso
                  - unlike its major competitors. The brand ditched the
                  traditional plastic bottle or milk carton packaging in favour
                  of a take-away coffee cup style format.
                </p>
                <p>
                  Devondale Murray Goulburn briefed Hardhat on a campaign to
                  help accelerate the launch of the brand, with a focus on
                  winning favour with a new market of young, barista style
                  coffee lovers.
                </p>
                <h4>THE WORK</h4>
                <p>
                  Strategically, we set out to showcase the unique packaging
                  format, knowing that on shelf recognition is crucial for
                  driving in-store sales. At the same time, we wanted to
                  highlight the inclusion of real espresso, a meaningful
                  differentiator in an otherwise inauthentic category.
                </p>
                <p>
                  Creatively, we aimed to place 8 Bar Iced Coffee alongside
                  iconic, Australian cafe culture - where we knew it belonged.
                </p>
                <p>
                  To ensure that the campaign achieved maximum efficiency, we
                  created a suite of assets that accounted for the nuances of
                  each platform, as well as the behaviours of the people who use
                  them. This meant that the creative needed to be:
                </p>
                <ul>
                  <li>
                    Instantly attention-grabbing - to stop thumbs that
                    compulsively scroll through busy newsfeeds on Facebook and
                    Instagram.
                  </li>
                  <li>
                    Quick to communicate - Five seconds is often all an audience
                    will give us to introduce the product, the brand and the
                    story we’re trying to tell.{" "}
                  </li>
                  <li>
                    Successful without sound - with over 90% of video content
                    consumed on social media without audio, the message needed
                    to be conveyed with visuals alone.
                  </li>
                </ul>
                <p>
                  The final creative was a visually arresting, slightly-dark but
                  humorous narrative showing the heartbreak you’ll cause when
                  you break it to your regular barista you’ve ditched him for
                  the convenience of 8 Bar Iced Coffee.
                </p>
                <p>
                  The spot includes a 15-second core edit, which spearheaded the
                  campaign to achieve mass reach across both social and YouTube.
                  This edit was developed to reach a mass audience and introduce
                  8 Bar Iced Coffee, the cause of local baristas’ heartache,
                  nation-wide.
                </p>
                <p>
                  The core edit was followed by multiple 6-second heart-ache
                  variations which built on the initial narrative, designed only
                  to be shown to audience viewers that had watched through the
                  entirety of the core edit using sequential targeting
                  techniques across both social and YouTube. This technique
                  helps achieve frequency without causing wear-out, and building
                  out the campaign narrative without excess media spend.
                </p>
              </div>
            </div>
            <a
              onClick={() => setShowFullStudy(!showFullStudy)}
              href="#"
              className="case-study-more button black button-partnership no-wrapper"
            >
              {!showFullStudy ? "READ FULL CASE STUDY" : "-"}
            </a>
          </section>
          <section className="hhd-case-image-block js-fade-in-animation">
            <div className="block-half">
              <div className="block-half">
                <img src={`${block2}`} width="100%" />
              </div>
              <div className="block-half">
                <img src={`${block3}`} width="100%" />
              </div>
              <div className="block-full">
                <img src={`${block4}`} width="100%" />
              </div>
            </div>
            <div className="block-half">
              <img src={`${block1}`} width="100%" />
            </div>
          </section>
          <section className="hhd-case-bigscreen-video js-hhd-video no-padding">
            <button className="play-vid" onClick={handleOpenVideo}>
              {" "}
              <div className="js-video-container latest-work-video-container">
                <img src={banner2x} width="100%" />
              </div>
              <span className="close-vid">
                <a href="#" className="close-vid-btn">
                  <i className="icon--stop" />
                </a>
              </span>
            </button>

            <ModalVideo
              channel="youtube"
              isOpen={showVideo}
              videoId="Wfam5n_f5_M"
              onClose={handleCloseVideo}
            />
          </section>
        </div>
      </div>
    </>
  )
}
export default EightBar
